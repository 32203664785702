<template>
  <div>
    <Heading>Opening Times</Heading>
    <div v-for="(day, index) in days" :key="index" class="grid grid-cols-2 text-left mx-auto gap-x-8">
      <div class="text-right">{{ day.date }}</div>
      <div>{{ day.hours }}</div>
    </div>
    <p class="mt-8">Please note: Face masks must be worn inside the store, unless proof of exemption can be shown.</p>
  </div>
</template>
<script>
import Heading from "@/Heading"

export default {
  name: 'OpeningHours',
  components: {Heading},
  data() {
    return {
      days: [
        {
          date: 'Thursday',
          hours: '10am to 5pm',
        },
        {
          date: 'Friday',
          hours: '10am to 5pm',
        },
        {
          date: 'Saturday',
          hours: '10am to 5pm',
        },
        {
          date: 'Sunday',
          hours: '11am to 4pm',
        },
      ]
    }
  }
}
</script>
