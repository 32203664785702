<template>
  <h2 class="text-2xl">
    <slot></slot>
  </h2>
</template>
<script>
export default {
  name: 'Heading'
}
</script>
