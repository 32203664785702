<template>
  <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d795.1797353244782!2d-0.5988118821158356!3d52.298726040443405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4877a339aaa7393b%3A0x9e637edf133e66c0!2sUnity+House+Emporium!5e0!3m2!1sen!2suk!4v1496860378551"
      class="max-w-lg w-full mx-auto"
      height="450" frameborder="0"
      style="border:0;"
      allowfullscreen
  />
</template>
<script>
export default {
  name: 'Location'
}
</script>
