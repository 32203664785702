<template>
  <div>
    <Heading>Contact</Heading>
    <p class="mb-8"><a href="mailto:steve@unityhouseemporium.co.uk">steve@unityhouseemporium.co.uk</a></p>
    <p class="mb-8">We also buy Gold & Silver.</p>
    <StreetAddress class="mb-8"/>
    <Location/>
  </div>
</template>
<script>
import Heading from "@/Heading"
import Location from "@/Location"
import StreetAddress from "@/StreetAddress"

export default {
  name: 'Contact',
  components: {Heading, Location, StreetAddress}
}
</script>
