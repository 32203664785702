<template>
  <img src="logo.png" alt="Unity House Emporium">
</template>

<script>
export default {}
</script>

<style scoped>

</style>
