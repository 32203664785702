<template>
  <div>
    <Heading>About</Heading>
    <p>Unity House Emporium offers for sale an eclectic collection of Antiques, Collectibles, Retro, Vintage, Veteran,
      Ancient, Military, Artwork, Period Furniture, Contemporary Classics, Vinyl Records, Musical Instruments and
      equipment, Memorabilia &amp; Curios and an extensive Military Books section... all housed in a unique period
      building with original features. There's always a great deal on offer.</p>
  </div>
</template>
<script>
import Heading from "@/Heading"

export default {
  name: 'About',
  components: {Heading}
}
</script>
