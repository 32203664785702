<template>
  <address>
    Unity House Emporium<br>
    80 Higham Road<br>
    Rushden<br>
    NN10 6DF
  </address>
</template>
<script>
export default {
  name: 'StreetAddress'
}
</script>
