<template>
  <div id="app" class="flex flex-col container mx-auto px-8 lg:px-0 gap-y-8 text-center mb-8 max-w-3xl">
    <div class="flex justify-center mt-8">
      <Logo class="lg:w-1/2"></Logo>
    </div>

    <p>We are open again as of 20th May 2021, and look forward to seeing customers old and new.</p>

    <About/>
    <OpeningHours/>
    <Contact/>
  </div>
</template>

<script>
import Logo from "@/Logo";
import About from "@/About";
import OpeningHours from "@/OpeningHours";
import Contact from "@/Contact";

export default {
  name: 'App',
  components: {
    Contact,
    OpeningHours,
    About,
    Logo,
  }
}
</script>
